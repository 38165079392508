import { v4 as generateGuid } from 'uuid';

const defaultNumberValue = 0;
const defaultStringValue = 'string';
const defaultCharValue = 'char';
const defaultDate = new Date().toJSON();
const defaultGuid = generateGuid();
const defaultBoolean = false;

const defaultValueMapping: Record<
    string,
    string | number | boolean | object | null
> = {
    'system.string': defaultStringValue,
    'system.string[]': [defaultStringValue, defaultStringValue],
    'system.collections.generic.list`1[system.string]': [
        defaultStringValue,
        defaultStringValue,
    ],
    'system.nullable<system.string>': defaultStringValue,
    'system.char': defaultCharValue,
    'system.char[]': [defaultCharValue, defaultCharValue],
    'system.collections.generic.list`1[system.char]': [
        defaultCharValue,
        defaultCharValue,
    ],
    'system.nullable<system.char>': defaultCharValue,
    'system.int16': defaultNumberValue,
    'system.int16[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.int16]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.int16>': defaultNumberValue,
    'system.int32': defaultNumberValue,
    'system.int32[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.int32]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.int32>': 0,
    'system.int64': defaultNumberValue,
    'system.int64[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.int64]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.int64>': defaultNumberValue,
    'system.int128': defaultNumberValue,
    'system.int128[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.int128]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.int128>': defaultNumberValue,
    'system.sbyte': defaultNumberValue,
    'system.sbyte[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.sbyte]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.sbyte>': defaultNumberValue,
    'system.intptr': defaultNumberValue,
    'system.intptr[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.intptr]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.intptr>': defaultNumberValue,
    'system.byte': defaultNumberValue,
    'system.byte[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.byte]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.byte>': defaultNumberValue,
    'system.uint16': defaultNumberValue,
    'system.uint16[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.uint16]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.uint16>': defaultNumberValue,
    'system.uint32': defaultNumberValue,
    'system.uint32[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.uint32]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.uint32>': defaultNumberValue,
    'system.uint64': defaultNumberValue,
    'system.uint64[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.uint64]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.uint64>': defaultNumberValue,
    'system.uint128': defaultNumberValue,
    'system.uint128[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.uint128]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.uint128>': defaultNumberValue,
    'system.uintptr': defaultNumberValue,
    'system.uintptr[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.uintptr]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.uintptr>': defaultNumberValue,
    'system.half': defaultNumberValue,
    'system.half[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.half]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.half>': defaultNumberValue,
    'system.single': defaultNumberValue,
    'system.single[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.single]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.single>': defaultNumberValue,
    'system.double': defaultNumberValue,
    'system.double[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.double]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.double>': defaultNumberValue,
    'system.decimal': defaultNumberValue,
    'system.decimal[]': [defaultNumberValue, defaultNumberValue],
    'system.collections.generic.list`1[system.decimal]': [
        defaultNumberValue,
        defaultNumberValue,
    ],
    'system.nullable<system.decimal>': defaultNumberValue,
    'system.datetime': defaultDate,
    'system.nullable<system.datetime>': defaultDate,
    'system.guid': defaultGuid,
    'system.nullable<system.guid>': defaultGuid,
    'system.boolean': defaultBoolean,
    'system.nullable<system.boolean>': defaultBoolean,
    'ost.esb.contract.body': defaultNumberValue,
};

const convertProperty = (prop: string) => {
    if (prop in defaultValueMapping) {
        return defaultValueMapping[prop];
    }

    if (prop.includes('ost.esb.contract.body')) {
        return defaultValueMapping['ost.esb.contract.body'];
    }

    return {};
};

export const convertProperties = (properties: Record<string, string>) => {
    const mappedProperties: Record<
        string,
        string | number | boolean | object | null
    > = {};
    Object.keys(properties).forEach(key => {
        const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        mappedProperties[lowerCaseKey] = convertProperty(
            properties[key].toLowerCase()
        );
    });
    return mappedProperties;
};

export const validateJson = (json: string) => {
    try {
        JSON.parse(json);
        return true;
    } catch {
        return false;
    }
};
